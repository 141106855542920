import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { selectOnboardingScreensList } from 'redux/Onboadring/selectors';
import { selectHideHeader, selectHideProgressBar } from 'redux/UiEffects/selectors';
import { selectOnboardingConfig } from 'redux/Testania/selectors';

import { generateQueryParams } from 'helpers/utilsUpdated';

import { Context } from '../../../App';

const BACK_BUTTON_PATHS = [
  '/ob-gp-explanation',
  '/ob-best-graph',
  '/ob-examining',
  '/ob-health-check',
  '/ob-body-signals',
  '/ob-choose-us',
  '/ob-fertility-target',
  '/ob-doctor-comment',
  '/ob-orgasms-comeback',
  '/ob-orgasm-benefits',
  '/ob-sex-graph',
  '/ob-boost-pleasure',
  '/ob-patterns',
  '/ob-sex-start',
  '/ob-intro-gp-flow',
  '/ob-intro-tc-flow',
  '/ob-social-proof-flow',
  '/ob-choose-us-flow',
  '/ob-doctor-comment-tc',
  '/ob-social-proof-flow-tc',
  '/ob-gn-review-first',
  '/ob-gn-chart',
  '/ob-gn-unlock',
  '/ob-gn-half-way',
  '/ob-gn-loading',
  '/ob-gn-good-hands',
  '/ob-gn-not-enough',
  '/ob-choose-us-cleanse',
  '/ob-choose-us-real',
  '/ob-couple-bond',
  '/ob-sex-state',
  '/ob-improve-sex',
  '/ob-vibrant-sex',
  '/ob-boost-pleasure-new',
  '/ob-choose-us-sex',
  '/ob-self-aware',
  '/ob-sex-expert',
  '/ob-meno-graph',
  '/ob-pg-choose-us',
  '/ob-pg-baby-exploration',
  '/ob-pg-baby-mind',
  '/ob-pg-question-answered',
  '/ob-pg-quick-checkup',
  '/ob-pg-experts',
  '/ob-pg-duedate-calculator',
];

export const useHeader = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const onboardingScreensList = useSelector(selectOnboardingScreensList);
  const onboardingConfig = useSelector(selectOnboardingConfig) || [];
  const isHideProgressBar = useSelector(selectHideProgressBar);
  const isHideHeader = useSelector(selectHideHeader);

  // @ts-ignore
  const [, setFlow] = useContext(Context);
  const queryParams = generateQueryParams();

  const currentLocationName = location.pathname.replace(/(\/([a-z]{2})\/)|\//g, '');
  const currentLocationId = currentLocationName.replace(/-/g, '_');
  const isObScreen = onboardingConfig.find(({ id }) => id === currentLocationId);

  const currentPageCount = onboardingScreensList && onboardingScreensList[currentLocationName]?.index;
  const screensNumber = onboardingScreensList && Object.keys(onboardingScreensList).length;

  const calculateProgressWidth = () => (currentPageCount / screensNumber) * 100 + '%';
  // const onboardingHeader = /* onboardingScreensList && onboardingScreensList[currentLocationName] && */ !isHideProgressBar;
  const onboardingHeader = onboardingConfig && isObScreen && !isHideProgressBar;

  const isRestricted = !!localStorage.getItem('yearRestriction');
  const restrictHeader = !isRestricted;

  const isStartPage = (pathname === '/ob-social-proof') || (pathname === '/ob-intro-tc') || (pathname === '/ob-choose-us');
  const isShowBackButton = isStartPage || BACK_BUTTON_PATHS.includes(pathname);

  /* To disable smooth scroll on mobile Safari */
  const isDisabledSmoothScroll = pathname === '/ob-intro-valueprops';

  const handleBackButtonClick = () => {
    if (isStartPage) {
      localStorage.removeItem('userGoal');
      localStorage.removeItem('testania_name');
      localStorage.removeItem('config');

      setFlow(null);
      navigate({
        pathname: '/ob-user-goal-vertical',
        search: queryParams,
      }, { state: { isAnimated: true } });
      // navigate(0);
    } else {
      navigate(-1);
    }
  };

  return {
    isHideHeader,
    isShowBackButton,
    isDisabledSmoothScroll,
    restrictHeader,
    onboardingHeader,
    handleBackButtonClick,
    calculateProgressWidth,
  };
};

