export const SET_URL_PARAMS = 'SET_URL_PARAMS';
export const SET_ONBOARDING_SCREENS_LIST = 'SET_ONBOARDING_SCREENS_LIST';

export const SET_USER_GOAL = 'SET_USER_GOAL';
export const SET_CYCLE_REGULARITY = 'SET_CYCLE_REGULARITY';
export const SET_CYCLE_DURATION = 'SET_CYCLE_DURATION';
export const SET_PERIOD_DURATION = 'SET_PERIOD_DURATION';
export const SET_BIRTH_YEAR = 'SET_BIRTH_YEAR';
export const SET_HEALTH_PROBLEMS = 'SET_HEALTH_PROBLEMS';
export const SET_FIRST_PREGNANCY = 'SET_FIRST_PREGNANCY';
export const SET_LIFESTYLE_ADJUSTMENT = 'SET_LIFESTYLE_ADJUSTMENT';
export const SET_JOB_TYPE = 'SET_JOB_TYPE';
export const SET_CONCEPTION_LENGTH = 'SET_CONCEPTION_LENGTH';
export const SET_OVULATION_CALCULATION = 'SET_OVULATION_CALCULATION';
export const SET_DOCTOR_CHECKUP = 'SET_DOCTOR_CHECKUP';
export const SET_REPRODUCTIVE_DISORDER = 'SET_REPRODUCTIVE_DISORDER';
export const SET_PRENATAL_SUPPLEMENTS = 'SET_PRENATAL_SUPPLEMENTS';
export const SET_INTERESTING_TOPICS_GP = 'SET_INTERESTING_TOPICS_GP';
export const SET_MOOD_SWINGS = 'SET_MOOD_SWINGS';
export const SET_MENTAL_HEALTH = 'SET_MENTAL_HEALTH';
export const SET_SEX_ISSUES = 'SET_SEX_ISSUES';
export const SET_BIRTH_CONTROL = 'SET_BIRTH_CONTROL';
export const SET_INTERESTING_TOPICS_TC = 'SET_INTERESTING_TOPICS_TC';
export const SET_EXPERIENCED_SYMPTOMS = 'SET_EXPERIENCED_SYMPTOMS';
export const SET_HEIGHT = 'SET_HEIGHT';
export const SET_WEIGHT = 'SET_WEIGHT';
export const SET_HORMONAL_CONTRACEPTION = 'SET_HORMONAL_CONTRACEPTION';
export const SET_LAST_PERIOD = 'SET_LAST_PERIOD';
export const SET_SYMPTOMS = 'SET_SYMPTOMS';
export const SKIP_ONBOARDING = 'SKIP_ONBOARDING';
export const INIT_AUTO_LOGIN = 'INIT_AUTO_LOGIN';
export const SET_HORMONAL_BALANCE = 'SET_HORMONAL_BALANCE';
export const SET_WHEN_TO_CONCEIVE = 'SET_WHEN_TO_CONCEIVE';
export const SET_GOAL_SEX_NEW = 'SET_GOAL_SEX_NEW';
export const SET_RELATIONSHIPS = 'SET_RELATIONSHIPS';
export const SET_SEX_PREGNANT = 'SET_SEX_PREGNANT';
export const SET_SEX_MENSTRUATION = 'SET_SEX_MENSTRUATION';
export const SET_GOAL_GP_NEW = 'SET_GOAL_GP_NEW';
export const SET_GN_HEALTH_ISSUES = 'SET_GN_HEALTH_ISSUES';
export const SET_GOAL_PREGNANCY = 'SET_GOAL_PREGNANCY';
export const SET_PREGNANCY_MODEL = 'SET_PREGNANCY_MODEL';
