import * as actionTypes from './actionTypes';

// import { OnboardingStore } from 'types/onboarding/onboardingStore';
import { InferValueTypes } from 'types/commonInterfaces';

import { DEFAULT_EMPTY_URL_PARAMS } from 'constants/onboardingScreensList';

import * as action from './actions';

type ActionType = ReturnType<InferValueTypes<typeof action>>;

const initialState: any = {
  urlParams: DEFAULT_EMPTY_URL_PARAMS,
  cycle_regularity: null,
  cycleDuration: null,
  cycleRegularity: null,
  periodDuration: null,
  healthProblems: null,
  birthYear: null,
  userGoal: null,
  onboardingScreensList: null,
  firstPregnancy: null,
  lifestyleAdjustment: null,
  conceptionLength: null,
  height: null,
  weight: null,
  lastPeriod: null,
  hormonalBalance: null,
  goalSexNew: null,
  relationships: null,
  sexPregnant: null,
  sexMenstruation: null,
  goalGpNew: null,
  gnHealthIssues: null,
  goalPregnancy: [],
  pregnancyModel: null,
};

const OnboardingReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actionTypes.SET_URL_PARAMS: {
      const stringifyData = JSON.stringify(action.payload);

      localStorage.setItem('urlParams', stringifyData);

      return { ...state, urlParams: action.payload };
    }

    case actionTypes.SET_ONBOARDING_SCREENS_LIST:
      return { ...state, onboardingScreensList: action.payload };

    case actionTypes.SET_USER_GOAL: {
      const userGoal = action.payload;

      localStorage.setItem('userGoal', userGoal);

      return { ...state, userGoal: action.payload };
    }

    case actionTypes.SET_CYCLE_REGULARITY:
      return { ...state, cycleRegularity: action.payload };

    case actionTypes.SET_CYCLE_DURATION:
      return { ...state, cycleDuration: action.payload };

    case actionTypes.SET_PERIOD_DURATION:
      return { ...state, periodDuration: action.payload };

    case actionTypes.SET_BIRTH_YEAR:
      return { ...state, birthYear: action.payload };

    case actionTypes.SET_HEALTH_PROBLEMS:
      return { ...state, healthProblems: action.payload };

    case actionTypes.SET_FIRST_PREGNANCY:
      return { ...state, firstPregnancy: action.payload };

    case actionTypes.SET_LIFESTYLE_ADJUSTMENT:
      return { ...state, lifestyleAdjustment: action.payload };

    case actionTypes.SET_JOB_TYPE:
      return { ...state, jobType: action.payload };

    case actionTypes.SET_CONCEPTION_LENGTH:
      return { ...state, conceptionLength: action.payload };

    case actionTypes.SET_OVULATION_CALCULATION:
      return { ...state, ovulationCalculation: action.payload };

    case actionTypes.SET_DOCTOR_CHECKUP:
      return { ...state, doctorCheckup: action.payload };

    case actionTypes.SET_REPRODUCTIVE_DISORDER:
      return { ...state, reproductiveDisorder: action.payload };

    case actionTypes.SET_PRENATAL_SUPPLEMENTS:
      return { ...state, prenatalSupplements: action.payload };

    case actionTypes.SET_INTERESTING_TOPICS_GP:
      return { ...state, interestingTopicsGp: action.payload };

    case actionTypes.SET_MOOD_SWINGS:
      return { ...state, moodSwings: action.payload };

    case actionTypes.SET_MENTAL_HEALTH:
      return { ...state, mentalHealth: action.payload };

    case actionTypes.SET_SEX_ISSUES:
      return { ...state, sexIssues: action.payload };

    case actionTypes.SET_BIRTH_CONTROL:
      return { ...state, birthControl: action.payload };

    case actionTypes.SET_INTERESTING_TOPICS_TC:
      return { ...state, interestingTopics: action.payload };

    case actionTypes.SET_EXPERIENCED_SYMPTOMS:
      return { ...state, experiencedSymptoms: action.payload };

    case actionTypes.SET_HEIGHT:
      return { ...state, height: action.payload };

    case actionTypes.SET_WEIGHT:
      return { ...state, weight: action.payload };

    case actionTypes.SET_HORMONAL_CONTRACEPTION:
      return { ...state, hormonalContraception: action.payload };

    case actionTypes.SET_LAST_PERIOD:
      return { ...state, lastPeriod: action.payload };

    case actionTypes.SET_SYMPTOMS:
      return { ...state, symptoms: action.payload };

    case actionTypes.SET_HORMONAL_BALANCE:
      return { ...state, hormonalBalance: action.payload };

    case actionTypes.SET_WHEN_TO_CONCEIVE:
      return { ...state, whenToConceive: action.payload };

    case actionTypes.SET_GOAL_SEX_NEW:
      return { ...state, goalSexNew: action.payload };

    case actionTypes.SET_RELATIONSHIPS:
      return { ...state, relationships: action.payload };

    case actionTypes.SET_SEX_PREGNANT:
      return { ...state, sexPregnant: action.payload };

    case actionTypes.SET_SEX_MENSTRUATION:
      return { ...state, sexMenstruation: action.payload };

    case actionTypes.SET_GOAL_GP_NEW:
      return { ...state, goalGpNew: action.payload };

    case actionTypes.SET_GN_HEALTH_ISSUES:
      return { ...state, gnHealthIssues: action.payload };

    case actionTypes.SET_GOAL_PREGNANCY:
      return { ...state, goalPregnancy: action.payload };

    case actionTypes.SET_PREGNANCY_MODEL:
      return { ...state, pregnancyModel: action.payload };

    default:
      return state;
  }
};

export default OnboardingReducer;
