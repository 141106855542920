const STAGE_DOMAIN_ID = '8366e11c-b0a0-4a36-8a3e-fae0ddd7797e';
const PROD_DOMAIN_ID = 'dc6e06a8-ed14-4aa6-8d8b-de1f1c8aa35f';

const getDomainId = () => {
  const domain = document.location.host;

  return (domain.includes('femia.io') || domain.includes('quiz.femia.health')) ? PROD_DOMAIN_ID : STAGE_DOMAIN_ID;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const initOneTrust = () => {
  const domainId = getDomainId();
  let retryCount = 0;
  const maxRetries = 30; // Define the maximum number of retry attempts.
  const retryInterval = 3000; // Define the retry interval in milliseconds

  const retryLoading = (errorMessage) => {
    const isOneTrustError = errorMessage.includes('otSDKStub.js') || errorMessage.includes('otBannerSdk.js');

    if (!isOneTrustError || window.OneTrust) return;

    if (retryCount < maxRetries) {
      retryCount++;

      console.info('Retrying to load OneTrust...', retryCount);

      setTimeout(loadScript, retryInterval);
    } else {
      handleLoadError();
    }
  };

  const loadScript = () => {
    localStorage.setItem('oneTrustInited', String(1));

    const { head } = document;
    const script = document.createElement('script');

    script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    script.type = 'text/javascript';
    script.setAttribute('data-document-language', 'true');
    script.setAttribute('data-domain-script', domainId);
    script.setAttribute('data-attempt', domainId);

    script.onerror = function() {
      retryLoading(this.src);
    };

    head.insertBefore(script, head.firstChild);
  };

  const handleLoadError = () => {
    localStorage.setItem('isOneTrustError', 'OneTrust Error: Failed to load the script');
  };


  if (domainId) {
    window.addEventListener('error', function(errorObject) {
      const errorMessage = (errorObject.error && errorObject.error.stack) || '';

      retryLoading(errorMessage);
    });

    loadScript();
  } else {
    const errorMessage = 'OneTrust Error: No ID for this domain';

    localStorage.setItem('isOneTrustError', errorMessage);
    console.error(errorMessage);
  }
};
